<template>
  <div>
    <!-- <lottery-add-new
      :is-add-new-user-sidebar-active.sync="isSidebarActive"
      :item.sync="dataEditable"
      @refetchData="refetchData"
    /> -->

    <b-card
      no-body
    >
      <div class="card-header">
        <b-card-title>Sorteos</b-card-title>
      </div>

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!-- <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label> -->
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end gap-1">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block"
                placeholder="Buscar..."
              />
              <b-button
                variant="primary"
                @click="exportList"
              >
                <span class="text-nowrap">Exportar</span>
              </b-button>
              <b-button
                variant="primary"
                @click="$router.push({ name: 'lottery-add' })"
              >
                <span class="text-nowrap">Nuevo sorteo</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        :items="fetchList"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
        responsive="sm"
        primary-key="id"
        empty-text="No se encontraron sorteos"
        show-empty
        striped
      >
        <!-- Column: Status -->
        <template #cell(status)="data">
          {{ data.item.status ? 'SI' : 'NO' }}
        </template>

        <!-- Column: Status -->
        <template #cell(countAwards)="data">
          {{ data.item.countAwards ? data.item.countAwards : 0 }}
        </template>

        <!-- Column: schedule_end -->
        <template #cell(schedule_end)="data">
          {{ data.item.schedule_end ? data.item.schedule_end : 0 }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`row-${data.item.id}-go-icon`"
              icon="EyeIcon"
              size="16"
              class="cursor-pointer"
              @click="$router.push(`/sorteo/${data.item.id}`)"
            />
            <b-tooltip
              title="Ir al Sorteo"
              :target="`row-${data.item.id}-go-icon`"
            />

            <feather-icon
              :id="`row-${data.item.id}-edit-icon`"
              icon="EditIcon"
              size="16"
              class="mx-1 cursor-pointer"
              @click="$router.push({ name: 'lottery-edit', params: { id: data.item.id } })"
            />
            <b-tooltip
              title="Editar sorteo"
              :target="`row-${data.item.id}-edit-icon`"
            />

            <div class="d-inline">
              <feather-icon
                v-if="!data.item.loading"
                :id="`row-${data.item.id}-delete-icon`"
                icon="TrashIcon"
                size="16"
                class="cursor-pointer"
                @click="onDelete(data.item)"
              />
              <b-spinner
                v-if="data.item.loading"
                :id="`row-${data.item.id}-delete-icon`"
                small
              />
              <b-tooltip
                :title="data.item.loading ? 'Eliminando...' : 'Eliminar sorteo'"
                :target="`row-${data.item.id}-delete-icon`"
              />
            </div>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Mostrando {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BCard,
  BCardTitle,
  BRow,
  BCol,
  BFormInput,
  BButton,
  // BDropdown,
  // BDropdownItem,
  BPagination,
  BTooltip,
  BSpinner,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
// import LotteryAddNew from './LotteryAddNew.vue'
import useLottery from './useLottery'

export default {
  components: {
    // LotteryAddNew,

    BCard,
    BTable,
    BCardTitle,
    BRow,
    BCol,
    BFormInput,
    BButton,
    // BDropdown,
    // BDropdownItem,
    BPagination,
    BTooltip,
    BSpinner,
  },
  setup() {
    const {
      fetchList,
      destroy,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      exportExcel,
    } = useLottery()

    const isSidebarActive = ref(false)
    const dataEditable = ref(null)

    function onEdit(item) {
      console.log(item.id)
    }

    async function onDelete(item) {
      try {
        const result = await this.$swal({
          title: '¿Estas seguro?',
          text: '¡No podrás revertir esta acción!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '¡Sí, bórralo!',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })

        if (result.value) {
          item.loading = true
          await destroy(item.id)
          item.loading = false
          refetchData()

          this.$swal({
            icon: 'success',
            title: '¡Borrado!',
            text: 'El sorteo ha sido borrado',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      } catch (error) {
        console.log(error)
      }
    }

    function exportList() {
      exportExcel({
        paginate: totalList.value,
        page: 1,
        sortBy: 'id',
        sortDesc: true,
      })
    }

    return {
      isSidebarActive,
      dataEditable,
      refListTable,

      tableColumns,
      sortBy,
      isSortDirDesc,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      fetchList,
      refetchData,
      onEdit,
      onDelete,
      exportList,
    }
  },
}
</script>
